<template>
  <div class="terms_sections pb-5">
    <section>
      <article style="
          padding-top: 50px;
          border: 1px solid transparent;
            background-color: #F0F9EB;
          ">
        <div
          class="container"
          style="margin: 50px auto; text-align: left"
        >
          <div class="terms_introduction">
            <p class="locus_word_style">
              <small>ACCESS UGANDA SAFARIS</small>
            </p>
            <h2 style="font-weight: 600; font-size: 2em">
              Terms and Conditions.
            </h2>
            <p style="font-weight: 300">
              There are the general terms and conditions governing your use of this website and our services.
            </p>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="container">
        <el-collapse
          v-model="activeNames"
          @change="handleChange"
        >
          <el-collapse-item
            title="Introduction"
            name="1"
          >
            <div>
              <p>Welcome to the www.accessugandasafaris.com website ("Website", "website", "Site" or "site"). This website, its pages, the content, services and infrastructure are owned, operated and provided by ACCESS UGANDA TOURS("AccessUgandaTours", "Us", "us, "We" or "we") or other parties. The website and its content is provided for your personal, non-commercial use only, subject to the terms of use as set out below. These terms of use (this "Agreement") set forth the terms and conditions governing your use of this website.</p>
            </div>
            
          </el-collapse-item>
          <el-collapse-item
            title="Modifications to this Agreement"
            name="2"
          >
            <div>We may make changes to this Agreement from time to time at our sole discretion. As soon as this page is updated the changes become effective. Please review this Agreement periodically. Your continued use of our website following the change of this Agreement will constitute your acceptance of any such changes.</div>
            
          </el-collapse-item>
          <el-collapse-item
            title="Privacy"
            name="3"
          >
            <div>We describe our current practices related to personally identifiable and other information collected through our website in our Privacy Policy and that we may update our policies and practices from time to time at our sole discretion. You acknowledge to have read and agree to this privacy policy.</div>
            
          </el-collapse-item>
          <el-collapse-item
            title="Your use of content and information"
            name="4"
          >
            <div>A variety of information, advice, recommendations, letters, messages, comments, posts, text, graphics, software, music, sound, photographs, videos, data and other materials ("Content" or "content") is available on the website. Some of the content is provided by us or our suppliers and other content is provided by persons who use our website ("User", "user", "Users" or "users"), such as user opinions and views provided via reviews, posts to chat rooms, blogs or message boards. While we strive to keep the content on our website accurate, complete and up to date- we cannot guarantee, and are not responsible for the accuracy, completeness, or timeliness of any content, whether provided by us, our suppliers, or by our users. Any opinions, advice, statements or other information expressed or made available by users or other third parties are those of the respective user or third party and not of us.

We don't have any obligation to prescreen, edit or remove any content provided by users that is posted on or available through our website.

Notwithstanding the foregoing, we will have the right (but not the obligation), at our sole discretion and for any reason, to prescreen, edit, refuse to accept, remove or move any such content.</div>
            
          </el-collapse-item>
          <el-collapse-item
            title="User generated content"
            name="5"
          >
            <div>User generated content ("User Content" or "user content") is information provided by our users with an intent to be published on our website (e.g. writing a review or post in our boards). You, as our user, are responsible for all user content that you submit, post or otherwise make available to or through our website.</div>

            <div>
              We don't claim ownership of user content, but by submitting, posting or otherwise making available to or through our website, you automatically grant us the right to use your user content in any way we desire. This includes the non-exclusive, perpetual, transferable, irrevocable right, with the right of sublicensing and without any royalty or compensation in return, to use, reproduce, adjust, translate, distribute, publish, create derivate works, to disclose and duplicate the content in all now known media en future media. You agree we can decide if and how your user content is credited and you also agree the user content provided may be indexed by search engines such as Google. You also grant us, and any third party appointed by us, the right to take (legal) actions as it deems necessary for the protection of the (copy)rights of your user content, including, but not by way of limitation, to take (legal) action in the name and on behalf of you.

            </div>

            <div>
              You agree not to submit, post or otherwise make available to or through our website any personally identifiable information about other people and/or abusive, obscene, vulgar, slanderous, hateful, threatening, sexually-orientated user content or any other material. You represent and warrant to us that such user content is not confidential and that you have all necessary permission to submit, post and otherwise make available such user content. You agree not to submit, post or otherwise make available to or through our website any commercial, advertising, promotional or spam-like user content or any other material. You also agree not to submit, post or otherwise make available to or through our website any user content that may violate any laws be it of your country, the Netherlands, any other country or international law. Violating any of these conditions may lead to you being immediately and permanently banned- with notification to your Internet Service Provider if deemed required by us, and we could decide to take other legal action. You agree that we have the right to remove, edit, move or close your account and/or any user content at any time should we see fit.


            </div>
            
          </el-collapse-item>
          <el-collapse-item
            title="Ownership and intellectual property rights"
            name="6"
          >
            <div>This website is owned by ACCESS UGANDA TOURS Ltd. All rights and interest in the content available via the website, the website's look and feel , the designs, trademarks, service marks and trade names displayed on the website and the website URL are the property of ACCESS UGANDA TOURS Ltd or its licensors, and are protected by copyrights, trademarks, patents or other proprietary rights and laws. You may not use any content available via the website in any manner or for any purpose without the prior written permission of us or, if applicable, our licensors. All rights not expressly granted in this Agreement are expressly reserved to ACCESS UGANDA TOURS Ltd and its licensors.</div>
            
          </el-collapse-item>
          <el-collapse-item
            title="Your contact with advertisers or other third parties"
            name="7"
          >
            <div>Your dealings with advertisers or other third parties found on or accessible through our website are solely between you and the third party. These dealings include, but are not limited to, your participation in promotions, the payment for and delivery of items such as safari tours if any, and any terms, conditions, warranties, or representations associated with such dealings. You access and use of such sites, including the content, items, or services on those sites, is solely at your own risk. We do not make any representations or warranties with respect to any content or privacy practices, or otherwise with respect to such third parties or any items or services that may be obtained from such third parties, and you agree that we will have no liability with respect to any loss or damage of any kind incurred as a result of any dealings between you and any third party, or as a result of the presence of such third parties on our website.</div>
            
          </el-collapse-item>
          <el-collapse-item
            title="Children under the age of 13"
            name="8"
          >
            <div>Our website is directed to adults and is not intended for children under the age of 13. Creating an account on our website, including but not limited to an account for our message boards, submitting a user review, other user content or enquiring about a safari tour is not allowed for children under the age of 13. If we learn that such actions were taken by a child under the age of 13, we will delete that account, any user content posted on our website as quickly as possible. If you believe an account was created by a child under the age of 13, please contact us by sending an email to info@accessugandasafaris.com</div>
            
          </el-collapse-item>
          <el-collapse-item
            title="Disclaimer of warranties"
            name="9"
          >
            <div>THE WEBSITE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. ACCESS UGANDA TOURS Ltd EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NONINFRINGEMENT.
ACCESS UGANDA TOURS DOES NOT MAKE ANY WARRANTY THAT THE WEBSITE WILL MEET YOUR REQUIREMENTS, OR THAT ACCESS TO THE WEBSITE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, OR THAT DEFECTS, IF ANY, WILL BE CORRECTED. ACCESS UGANDA TOURS MAKES NO WARRANTIES AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE WEBSITE OR AS TO THE ACCURACY, QUALITY, OR RELIABILITY OF ANY INFORMATION OBTAINED THROUGH THE WEBSITE.</div>
          </el-collapse-item>
          <el-collapse-item
            title="Limitations of liability"
            name="10"
          >
            <div>ACCESS UGANDA TOURS AND ITS AFFILIATES ASSUME NO RESPONSIBILITY FOR ANY CONSEQUENCE RELATING DIRECTLY OR INDIRECTLY TO ANY ACTION OR INACTION YOU TAKE BASED ON THE CONTENT AVAILABLE VIA THE WEBSITE. YOU MUST EVALUATE, AND BEAR ALL RISKS ASSOCIATED WITH, THE USE OF ANY CONTENT, INCLUDING ANY RELIANCE ON THE ACCURACY, COMPLETENESS, OR USEFULNESS OF SUCH CONTENT. YOU SPECIFICALLY ACKNOWLEDGE THAT ACCESS UGANDA TOURS IS NOT LIABLE FOR THE DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF USERS OR THIRD PARTIES.
ADDITIONALLY, IN NO EVENT WILL ACCESS UGANDA TOURS Ltd OR ITS AFFILIATES BE LIABLE FOR ANY SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES, INCLUDING, WITHOUT LIMITATION, ANY LOSS OF USE, LOSS OF PROFITS, LOSS OF DATA, COST OF PROCUREMENT OF SUBSTITUTE PRODUCTS OR SERVICES, OR ANY OTHER SUCH DAMAGES, HOWSOEVER CAUSED, AND ON ANY THEORY OF LIABILITY, WHETHER FOR BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE AND STRICT LIABILITY), OR OTHERWISE RESULTING FROM (1) THE USE OF, OR THE INABILITY TO USE THE WEBSITE; (2) THE COST OF PROCUREMENT OF SUBSTITUTE SERVICES, ITEMS, OR WEBSITES; (3) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (4) THE STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE WEBSITE; OR (5) ANY OTHER MATTER RELATING TO THE WEBSITE. THESE LIMITATIONS WILL APPLY WHETHER OR NOT ACCESS UGANDA TOURS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.</div>
            
          </el-collapse-item>
          <el-collapse-item
            title="Modification or suspension of our website"
            name="11"
          >
            <div>We may at any time modify, discontinue, or suspend the operation of our website, or any part thereof, temporarily or permanently, without notice to you.</div>
            
          </el-collapse-item>
          <el-collapse-item
            title="Change of ownership"
            name="12"
          >
            <div>If we are in the process of selling our company, our website, or substantial parts of our business, you agree we may disclose and/or transfer your personally identifiable information as well as other information to the (potential) new owner so they can better value our business and, if sold, continue to operate the service this website provides. </div>
            <div>You also agree that in the case the ownership of our company or website changes the rights, obligations and restrictions you have towards us, as set forth in this agreement, will be transferred to the new owner without notice to you and you accept the new owner as your new counter-party in this Agreement.</div>
            
          </el-collapse-item>
          <el-collapse-item
            title="Modification or suspension of our website"
            name="13"
          >
            <div>We may at any time modify, discontinue, or suspend the operation of our website, or any part thereof, temporarily or permanently, without notice to you.</div>
            
          </el-collapse-item>
          <el-collapse-item
            title="Complaints regarding content"
            name="14"
          >
            <div>
              <p>
                For making complaints regarding copyright infringement of our content or regarding our content in general, please send an email to info@accessugandasafaris.com.
              </p>
            </div>
          </el-collapse-item>
          <el-collapse-item
            title="Contact"
            name="15"
          >
            <div>
              <p>
                ACCESS UGANDA TOURS Ltd is a private limited liability company, incorporated under the laws of Uganda and has its offices National Theatre Plot 2,4,6 Dewinton Rd, Kampala Uganda.
                If you have any suggestions or remarks about this Agreement, please send an email to: info@accessugandasafaris.com.
              </p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </article>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeNames: ["1"],
    };
  },

  methods: {
    handleChange(val) {
      console.log(val);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.terms_sections {
  background-color: #f0f9eb;
}

.terms_sections h2 {
  font-size: 1.5em;
}

.terms_sections h3 {
  font-size: 1.3em;
  font-weight: 600;
}
.locus_word_style {
  font-size: 0.5em;
  font-weight: 300;
  word-spacing: 8px;
  letter-spacing: 5px;
}
.terms_introduction {
  width: 60%;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .terms_introduction {
    width: 100%;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .terms_introduction {
    width: 100%;
  }
}
</style>

<style>
.terms_sections .el-collapse-item__content {
  background-color: #f0f9eb !important;
}
.terms_sections .el-collapse-item {
  background-color: transparent !important;
}
.terms_sections .el-collapse-item__header {
  background-color: #f0f9eb !important;
}
.terms_sections .el-collapse-item__body {
  background-color: #f0f9eb !important;
}
.terms_sections .el-collapse-item__header {
  padding: 15px 0px;
  display: flex;
  align-items: center;
  height: 48px;
  line-height: 48px;
  cursor: pointer;
  border-bottom: 1px solid #93cb75 !important;
  font-size: 1.2em;
  font-weight: 600;
  transition: border-bottom-color 0.3s;
  outline: 0;
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .terms_sections .el-collapse-item__header {
    padding: 15px 0px;
    height: 48px;
    line-height: 48px;
    font-size: 1em;
    font-weight: 600;
  }
}
</style>
